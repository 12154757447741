import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PLAYLIST_ID_PARAM_NAME } from '../../hooks/use-playlist-path-id';
import { RESET_TOKEN_PARAM } from '../../hooks/use-reset-token-path';
import {
  VIDEO_ID_PARAM_NAME,
  VIDEO_LINK_PARAM_NAME,
} from '../../hooks/use-video-path-id';
import { AuthLayout } from '../../layouts/auth-layout';
import { BaseLayout } from '../../layouts/base-layout';
import { Account } from '../../pages/account';
import { AnalyticsPage } from '../../pages/analytics';
import { FinancePage } from '../../pages/finance';
import { ForgotPassword } from '../../pages/forgot-password';
import { ForgotPasswordComplete } from '../../pages/forgot-password-complete';
import { LinkStatistic } from '../../pages/link-statistic';
import { Login } from '../../pages/login';
import { PlaylistPage } from '../../pages/playlist';
import { PrivacyPolicyPage } from '../../pages/privacy-policy';
import { PublicOfferPage } from '../../pages/public-offer';
import { Registration } from '../../pages/registration';
import { UploadedVideos } from '../../pages/uploaded-videos';
import { Video } from '../../pages/video';
import { VideoCreate } from '../../pages/video-create';
import { VideoStatistics } from '../../pages/video-statistics';
import { ViewedVideos } from '../../pages/viewed-videos';
import { Paths, SecondaryPaths } from '../../types/common';

export const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={Paths.LOGIN} element={<Login />} />
        <Route path={Paths.REGISTRATION} element={<Registration />} />
        <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route
          path={`${Paths.FORGOT_COMPLETE}/:${RESET_TOKEN_PARAM}`}
          element={<ForgotPasswordComplete />}
        />
      </Route>

      <Route path={Paths.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={Paths.PUBLIC_OFFER} element={<PublicOfferPage />} />

      <Route element={<BaseLayout />}>
        <Route path={Paths.BASE} element={<UploadedVideos />} />
        <Route
          path={`${Paths.UPLOADED_VIDEOS}/*`}
          element={<UploadedVideos />}
        />
        <Route path={`${Paths.VIEWED_VIDEOS}/*`} element={<ViewedVideos />} />
        <Route path={Paths.ANALYTICS} element={<AnalyticsPage />} />
        <Route path={Paths.FINANCE} element={<FinancePage />} />
        <Route path={Paths.ACCOUNT} element={<Account />} />
        <Route path={Paths.VIDEO_CREATE} element={<VideoCreate />} />
        <Route
          path={`${Paths.VIDEO}/:${VIDEO_ID_PARAM_NAME}${SecondaryPaths.STATISTICS}`}
          element={<VideoStatistics />}
        />
        <Route
          path={`${Paths.VIDEO}/:${VIDEO_ID_PARAM_NAME}/*`}
          element={<Video />}
        />
        <Route
          path={`${Paths.VIDEO}/:${VIDEO_ID_PARAM_NAME}${SecondaryPaths.STATISTICS}/:${VIDEO_LINK_PARAM_NAME}`}
          element={<LinkStatistic />}
        />
        <Route
          path={`${Paths.PLAYLIST}/:${PLAYLIST_ID_PARAM_NAME}`}
          element={<PlaylistPage />}
        />
        <Route
          path={`${Paths.PLAYLIST}/:${PLAYLIST_ID_PARAM_NAME}${SecondaryPaths.PLAYLIST_ADD_VIDEO}`}
          element={<>some page</>}
        />
      </Route>
    </Routes>
  );
};
