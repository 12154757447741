import { RootCombine } from '../root';

export const selectFinance = (state: RootCombine) => state.finance;

export const selectLegalReport = (state: RootCombine) =>
  selectFinance(state).legalReportCreate;

export const selectListTariffPlans = (state: RootCombine) =>
  selectFinance(state).getTariffPlans;

export const selectListTariffPlansLoading = (state: RootCombine) =>
  selectFinance(state).getTariffPlans.loading;
