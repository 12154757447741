import { TFunction } from 'i18next';
import { FC, Fragment, useMemo } from 'react';

import { INFINITE_SYMBOL } from '../../../constants/symbols';
import { LangType } from '../../../state/locale/localeSlice';
import { EFinancePeriod, TFinancePlan } from '../../../types/finance';
import { boolToText } from '../../../utils/common';
import { NBSP } from '../../../utils/text-formatters';
import { useBoolInText } from '../../../utils/useBoolInText';
import {
  FeatureLabel,
  FeatureValue,
  PerPeriod,
  PlanFeatureWrapper,
  PlanName,
  PlanType,
  PriceWrapper,
  StyledButton,
  Sum,
  Wrapper,
} from './tariff-plan.styled';

type TProps = {
  planInfo: TFinancePlan;
  lang: LangType;
  period: EFinancePeriod;
  t: TFunction<string[]>;
  onSelect?: () => void;
  special?: boolean;
  current?: boolean;
};

export const TariffPlan: FC<TProps> = ({
  planInfo,
  lang,
  t,
  period,
  special,
  current,
  onSelect,
}) => {
  const isCorporatePlan = useMemo(
    () => planInfo.rate === 'corporate',
    [planInfo]
  );

  const buttonText = useMemo(
    () =>
      current
        ? t('tariffTextConstants.current')
        : !isCorporatePlan
          ? t('tariffTextConstants.pay')
          : t('tariffTextConstants.sendRequest'),
    [current, planInfo, isCorporatePlan]
  );

  const planTypeConvert = useMemo(
    () =>
      `${t('tariffTextConstants.type')} ${isCorporatePlan ? 'On-Premise' : 'Cloud'}`,
    [isCorporatePlan]
  );

  const uploadSizeConvert = useMemo(() => {
    if (planInfo && planInfo.storageLimits >= 100000000) return INFINITE_SYMBOL;
    if (planInfo && planInfo.storageLimits >= 1000000)
      return `${planInfo.storageLimits / 1000000} ${t('tariffTextConstants.storageSize.tb')}`;
    if (planInfo && planInfo.storageLimits >= 1000)
      return `${planInfo.storageLimits / 1000} ${t('tariffTextConstants.storageSize.gb')}`;
    return `${planInfo.storageLimits} ${t('tariffTextConstants.storageSize.mb')}`;
  }, [planInfo]);

  const videoLinksCountConvert = useMemo(() => {
    if (planInfo && planInfo.videoLinksCount >= 1000000) return INFINITE_SYMBOL;
    return planInfo.videoLinksCount;
  }, [planInfo]);

  const usersCountViewingStatsConvert = useMemo(() => {
    if (planInfo && planInfo.usersCountViewingStats >= 1000000)
      return INFINITE_SYMBOL;
    return `${planInfo.usersCountViewingStats} ${t('tariffTextConstants.record')}`;
  }, [planInfo]);

  const bandwidthConvert = useMemo(() => {
    if (planInfo && planInfo.bandwidth >= 100000000) return INFINITE_SYMBOL;
    return `${planInfo.bandwidth / 1000000} ${t('tariffTextConstants.storageSize.tb')}/${t('tariffTextConstants.monthFull')}`;
  }, [planInfo]);

  const supportConvert = useMemo(() => {
    if (
      planInfo &&
      planInfo.supportOptions.length === 1 &&
      planInfo.supportOptions.includes('chat_bot')
    )
      return t('tariffTextConstants.support.chatBot');

    if (
      planInfo &&
      planInfo.supportOptions.length === 2 &&
      planInfo.supportOptions.includes('chat_bot') &&
      planInfo.supportOptions.includes('mail')
    )
      return `${t('tariffTextConstants.support.chatBot')} 
              ${t('tariffTextConstants.support.divider')} 
              ${t('tariffTextConstants.support.email')}`;

    if (
      planInfo &&
      planInfo.supportOptions.length === 3 &&
      planInfo.supportOptions.includes('chat_bot') &&
      planInfo.supportOptions.includes('mail') &&
      planInfo.supportOptions.includes('phone')
    )
      return `${t('tariffTextConstants.support.chatBot')}, 
              ${t('tariffTextConstants.support.email')} 
              ${t('tariffTextConstants.support.divider')} 
              ${t('tariffTextConstants.support.phone')}`;
    return '';
  }, [planInfo]);

  return (
    <Wrapper special={boolToText(special)}>
      <PlanName>{t(`plan.${planInfo.rate}`)}</PlanName>
      <PlanType>{planTypeConvert}</PlanType>
      <PriceWrapper>
        {!isCorporatePlan ? (
          <Fragment>
            <Sum>{planInfo.price}</Sum>
            {NBSP}
            <PerPeriod>
              {lang === 'ru' ? '₽' : '$'}/{t(`tariffTextConstants.${period}`)}
            </PerPeriod>
          </Fragment>
        ) : (
          <Sum>{t('tariffTextConstants.needRequest')}</Sum>
        )}
      </PriceWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.storageSize`)}</FeatureLabel>
        <FeatureValue>{uploadSizeConvert}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.uploadWithoutSaving`)}</FeatureLabel>
        <FeatureValue>
          {useBoolInText(planInfo.isAddVideoWithoutUpload)}
        </FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.linksCount`)}</FeatureLabel>
        <FeatureValue>{videoLinksCountConvert}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.viewAnalytics`)}</FeatureLabel>
        <FeatureValue>{usersCountViewingStatsConvert}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.bandwidth`)}</FeatureLabel>
        <FeatureValue>{bandwidthConvert}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.pcApplication`)}</FeatureLabel>
        <FeatureValue>{useBoolInText(planInfo.isPCApp)}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.branding`)}</FeatureLabel>
        <FeatureValue>{useBoolInText(planInfo.isBrending)}</FeatureValue>
      </PlanFeatureWrapper>
      <PlanFeatureWrapper>
        <FeatureLabel>{t(`planFeatures.support`)}</FeatureLabel>
        <FeatureValue>{supportConvert}</FeatureValue>
      </PlanFeatureWrapper>
      <StyledButton
        type={current ? 'default' : 'primary'}
        block
        disabled={current}
        onClick={current ? undefined : onSelect}
        isActivePlan={Boolean(current)}
      >
        {buttonText}
      </StyledButton>
    </Wrapper>
  );
};
