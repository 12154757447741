import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { BaseModal } from '../../../components/modal/base-modal';
import { useSearchParams } from '../../../utils/useSearchParams';
import { SuccesPaymentIcon } from '../icons';
import {
    Content,
    Description,
    PlanName,
    ReadyButton,
    Title,
} from './SuccesPayment.styled';

type TProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    t: TFunction<string[]>;
};

export const SuccessPaymentModal: FC<TProps> = ({ open, setOpen, t }) => {
    const { removeAllParams } = useSearchParams();

    const isSelectedPlan = JSON.parse(
        localStorage.getItem('selectedPlan') as string
    );

    const closeModal = useCallback(() => {
        localStorage.removeItem('selectedPlan');
        removeAllParams();
        setOpen(false);
    }, []);

    return (
        <BaseModal
            width={600}
            open={open}
            setOpen={setOpen}
            title={null}
            footer={[]}
            closeIcon={false}
            maskClosable={false}
            t={t}
        >
            <Content>
                <SuccesPaymentIcon />
                <Title>
                    {t(`succesPaymentModal.planNameFirstPart`)}
                    <PlanName> «{t(`plan.${isSelectedPlan.name}`)}»</PlanName>{' '}
                    {t(`succesPaymentModal.planNameSecondPart`)}
                </Title>
                <Description>{t('succesPaymentModal.description')}</Description>
                <ReadyButton onClick={closeModal}>
                    {t('succesPaymentModal.readyButton')}
                </ReadyButton>
            </Content>
        </BaseModal>
    );
};
