import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { BaseModal } from '../../../components/modal/base-modal';
import { SuccesPaymentIcon } from '../icons';
import {
    Content,
    Description,
    ReadyButton,
    Title,
} from './RequestSendSuccess.styled';

type TProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    t: TFunction<string[]>;
};

export const RequestSendSuccessModal: FC<TProps> = ({ open, setOpen, t }) => {
    const closeModal = useCallback(() => setOpen(false), []);

    return (
        <BaseModal
            width={600}
            open={open}
            setOpen={setOpen}
            title={null}
            footer={[]}
            closeIcon={false}
            maskClosable={false}
            t={t}
            centered
        >
            <Content>
                <SuccesPaymentIcon />
                <Title>{t(`succesSendRequest.title`)}</Title>
                <Description> {t('succesSendRequest.description')} </Description>
                <ReadyButton onClick={closeModal}>
                    {t('succesSendRequest.readyButton')}
                </ReadyButton>
            </Content>
        </BaseModal>
    );
};
