import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import App from 'antd/lib/app';
import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { API } from '../api';
import { PageHeader } from '../components/common/page-header';
import { LS_LAYOUT_VALUE_LEY } from '../components/common/title-with-layout';
import { UploadVideoVariantsPopover } from '../components/common/upload-video-variants-popover';
import AddVideo from '../components/icons/add-video';
import { PageMenu } from '../components/uploaded-videos/page-menu';
import { Playlists } from '../components/uploaded-videos/playlists';
import { Videos } from '../components/uploaded-videos/videos';
import { useHandleLoadPlaylist } from '../hooks/playlists';
import { useLocalStorageValue } from '../hooks/use-local-storage-value';
import { usePlaylistHandleSave } from '../hooks/use-playlist-handle-save';
import { LocaleKeys } from '../locale';
import { PlaylistCreateModal } from '../modals/video/playlist-create-modal';
import { UploadWithLinkModal } from '../modals/video/upload-with-link-modal';
import { useAppDispatch } from '../state';
import { usePlaylistCurrentSelector } from '../state/playlists/selectors';
import {
  TFirstStage,
  createVideoNextStageAction,
  createVideoResetStateAction,
  createVideoSetFirstStageAction,
} from '../state/video/createSlice';
import { useCreateVideoSelector } from '../state/video/selectors';
import { Paths, SecondaryPaths } from '../types/common';
import { ESortOption, EVideoQueryMods, EVideoQueryPaths } from '../types/video';

export const ContentWrapper = styled('div') <{ layout: 'col' | 'grid' }>`
  display: grid;
  grid-template-columns: ${({ layout }) =>
    layout === 'grid' ? 'repeat(auto-fit, minmax(220px, 330px))' : '1fr'};
  column-gap: 40px;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: 1250px) {
    justify-content: center;
  }

  @media (max-width: 948px) {
    padding-left: 20px;
  }
`;

const LoadingWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
`;

const AddVideoButton = styled('button')`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  background-color: transparent;
  border: none;
  margin-right: 16px;
  font-weight: 500;
  transition: color 0.3s ease-out;
  padding: 8px;

  svg .primary,
  svg .secondary {
    transition: fill 0.3s ease-out;
  }

  &:hover {
    svg .primary {
      fill: ${({ theme }) => theme.COLORS.ACCENT._200};
    }

    svg .secondary {
      fill: ${({ theme }) => theme.COLORS.BLACK._100};
    }

    color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }
`;

export const Content: FC<{
  layout: 'col' | 'grid';
}> = ({ layout }) => {
  return (
    <ContentWrapper layout={layout}>
      <Outlet />
    </ContentWrapper>
  );
};

export const UploadedVideos: FC = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const inputRef = useRef<HTMLInputElement>(null);

  const { currentStage } = useCreateVideoSelector();

  const [playListModal, setPlayListModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [layout, setLayout] = useLocalStorageValue<'col' | 'grid'>(
    'grid',
    LS_LAYOUT_VALUE_LEY
  );

  const { data, loaded, pending } = usePlaylistCurrentSelector();

  const [playlistEditMode, setPlayListEditMode] = useState(false);
  const [playlistEditId, setPlayListEditId] = useState<string | null>(null);

  const [loadWithLinkModalOpen, setLoadWithLinkModalOpen] = useState(false);
  const [withSaving, setWithSaving] = useState(false);

  const [sortOption, setSortOption] = useState<ESortOption>(ESortOption.NEW);

  const handleChangeVideoList = useCallback((videoId: string) => {
    setEditSelected((prev) => {
      if (prev.includes(videoId)) {
        return prev.filter((el) => el !== videoId);
      } else {
        return [...prev, videoId];
      }
    });
  }, []);

  const handleLoadPlaylist = useHandleLoadPlaylist();

  useEffect(() => {
    const playlistId = searchParams.get(EVideoQueryPaths.PLAYLIST_ID);
    const mode = searchParams.get(EVideoQueryPaths.MODE);

    if (mode === EVideoQueryMods.ADD_VIDEO && playlistId) {
      setPlayListEditMode(true);
      setPlayListEditId(playlistId);
    } else {
      setPlayListEditMode(false);
      setPlayListEditId(null);
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    if (currentStage === 'second') {
      navigate(Paths.VIDEO_CREATE);
    }
  }, [currentStage]);

  const handleSaveStage = useCallback((data: Partial<TFirstStage>) => {
    dispatch(createVideoSetFirstStageAction(data));
  }, []);

  const handleUploadVideo = useCallback(
    async (fileFormData: FormData) => {
      handleSaveStage({
        pending: true,
      });
      const loading = message.loading(t('upload.pending'), 0);
      const videoItem = document.createElement('video');

      videoItem.preload = 'metadata';

      const file = fileFormData.get('file') as File | null;

      if (file) {
        const fileSize = file.size / 1000000000;

        if (fileSize >= 2) {
          loading();
          handleSaveStage({
            pending: false,
          });

          message.error(
            t('upload.limitText', {
              value: Math.round(fileSize * 10) / 10,
            })
          );
          return;
        }

        videoItem.src = URL.createObjectURL(file);

        handleSaveStage({
          file: file,
        });
      } else {
        loading();
        handleSaveStage({
          pending: false,
        });
        return;
      }

      videoItem.addEventListener('loadedmetadata', () => {
        API.videos
          .upload(fileFormData)
          .then((r) => {
            loading();
            message.success(t('upload.success'));
            handleSaveStage({
              videoDuration: videoItem.duration,
              uploadResponse: {
                ...r.data,
              },
              uploaded: true,
              pending: false,
              filled: true,
            });
            dispatch(createVideoNextStageAction());
            videoItem.remove();
          })
          .catch((err: any) => {
            if (
              err?.response?.data?.message.includes(
                'The video size limit for the tariff plan has been exceeded'
              )
            ) {
              message.error(t('upload.errorSizeLimit'));
            } else {
              message.error(t('upload.error'));
            }
            loading();
            videoItem.remove();
            handleSaveStage({
              uploaded: false,
              pending: false,
            });
          });
      });
    },
    [t]
  );

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.item(0)) {
        const file = target.files.item(0);

        if (file) {
          const formData = new FormData();
          formData.set('file', file);
          formData.set('mediaType', 'video');

          if (file.size < 2147483648) {
            void handleUploadVideo(formData);
          } else {
            void message.error(t('upload.sizeLimitError'));
          }

          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = null;
          }
        }
      }
      dispatch(createVideoResetStateAction());
    },
    [t]
  );

  useEffect(() => {
    if (
      playlistEditMode &&
      playlistEditId &&
      ((!pending && !loaded) || (loaded && data?.id !== playlistEditId))
    ) {
      void handleLoadPlaylist(playlistEditId);
    }
  }, [loaded, pending, data, playlistEditId, playlistEditMode]);

  useEffect(() => {
    if (data && playlistEditMode && !pending) {
      setEditSelected(data.videos?.map((el) => el.id) ?? []);
    }
  }, [data, playlistEditMode, pending]);

  const [
    handleSavePlaylist,
    operationProps,
    exitEditMode,
    editSelected,
    setEditSelected,
  ] = usePlaylistHandleSave();

  const handleUploadByLink = useCallback(() => {
    setWithSaving(true);
    setLoadWithLinkModalOpen(true);
  }, []);

  const handleLoadLinkWithoutUpload = useCallback(() => {
    setWithSaving(false);
    setLoadWithLinkModalOpen(true);
  }, []);

  return (
    <div>
      <input
        type="file"
        hidden
        id="add-video-file"
        ref={inputRef}
        onChange={handleChange}
      />
      <PageHeader
        rightAddon={
          <UploadVideoVariantsPopover
            onUploadWithLinkClick={handleUploadByLink}
            onWithoutUploadClick={handleLoadLinkWithoutUpload}
          >
            <AddVideoButton>
              <AddVideo />
              {t('videos.download.title')}
            </AddVideoButton>
          </UploadVideoVariantsPopover>
        }
      >
        {t('videos.uploaded')}
      </PageHeader>
      <PageMenu
        playlistEditMode={playlistEditMode}
        setPlayListModal={setPlayListModal}
        handleSavePlaylist={handleSavePlaylist}
        exitEditMode={exitEditMode}
        operationsButtonProps={operationProps}
        sortOption={sortOption}
        setSortOption={setSortOption}
      />
      {playlistEditMode ? (
        <ContentWrapper layout={layout}>
          {pending && (
            <LoadingWrapper>
              <LoadingOutlined />
            </LoadingWrapper>
          )}
          <Videos
            playlistEditMode={playlistEditMode}
            handleChangeVideoList={handleChangeVideoList}
            selectedVideos={editSelected}
            sortOption={sortOption}
            layout={layout}
          />
        </ContentWrapper>
      ) : (
        <Routes>
          <Route element={<Content layout={layout} />}>
            <Route
              path={SecondaryPaths.BASE}
              element={<Videos layout={layout} sortOption={sortOption} />}
            />
            <Route
              path={SecondaryPaths.PLAYLISTS}
              element={<Playlists layout={layout} sortOption={sortOption} />}
            />
          </Route>
        </Routes>
      )}
      <PlaylistCreateModal open={playListModal} setOpen={setPlayListModal} />
      <UploadWithLinkModal
        open={loadWithLinkModalOpen}
        setOpen={setLoadWithLinkModalOpen}
        withSaving={withSaving}
      />
    </div>
  );
};
