import Icon from '@ant-design/icons';

const SuccesPaymentSVG = () => (
    <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="100" height="100" rx="50" fill="#1E4141" />
        <path
            d="M29.5835 52.9165L41.2502 64.5832L70.4168 35.4165"
            stroke="#39D5C9"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export const SuccesPaymentIcon = () => <Icon component={SuccesPaymentSVG} />;
