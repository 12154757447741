type Infinite<T> = T | 'infinite';

export enum EPlanName {
  BASE = 'base',
  START = 'start',
  STANDARD = 'standard',
  BUSINESS = 'business',
  CORP = 'corporate',
}

export enum ECurrency {
  RUR = 'rur',
  USD = 'usd',
}

export enum ESupportKey {
  chatBot = 'chatBot',
  email = 'email',
  phone = 'phone',
}

export enum ETariffFeatureNames {
  storageSize = 'storageSize',
  uploadWithoutSaving = 'uploadWithoutSaving',
  linksCount = 'linksCount',
  viewAnalytics = 'viewAnalytics',
  bandwidth = 'bandwidth',
  pcApplication = 'pcApplication',
  branding = 'branding',
  support = 'support',
}

export enum EStorageUnit {
  MB = 'mb',
  GB = 'gb',
  TB = 'tb',
}

export type TariffFeatures = {
  [ETariffFeatureNames.storageSize]: {
    size: Infinite<string>;
    unit: EStorageUnit;
  };
  [ETariffFeatureNames.uploadWithoutSaving]: boolean;
  [ETariffFeatureNames.linksCount]: Infinite<number>;
  [ETariffFeatureNames.viewAnalytics]: Infinite<number>;
  [ETariffFeatureNames.bandwidth]: Infinite<number>;
  [ETariffFeatureNames.pcApplication]: boolean;
  [ETariffFeatureNames.branding]: boolean;
  [ETariffFeatureNames.support]: ESupportKey[];
};

export type TFinanceFeature<
  T extends ETariffFeatureNames = ETariffFeatureNames,
> = {
  name: T;
  value: TariffFeatures[T];
};

export type TPrice = number | 'need-request';

export type TFinancePlan = {
  bandwidth: number;
  createdAt: string;
  deployType: string;
  id: string;
  isAddVideoWithoutUpload: boolean;
  isBrending: boolean;
  isPCApp: boolean;
  killBillPlanName: string;
  period: string;
  price: number;
  rate: string;
  storageLimits: number;
  updatedAt: string;
  usersCountViewingStats: number;
  videoLinksCount: number;
  supportOptions: string[];
  isUnlimited: boolean;
  remainingTariffTime: number;
};

export type TFinanceClear = Omit<
  TFinancePlan,
  'pricePerMonth' | 'pricePerYear'
> & {
  price: TPrice;
};

export type TTariffPlan = {
  features: TariffFeatures;
  pricePerMonth: Record<ECurrency, TPrice>;
  pricePerYear: Record<ECurrency, TPrice>;
} & Omit<TFinancePlan, 'features' | 'pricePerMonth' | 'pricePerYear'>;

export enum EFinancePeriod {
  MONTH = 'monthly',
  YEAR = 'annual',
}

export enum EPaymentMethod {
  Individual = 'Individual',
  Legal = 'Legal',
}

export type TLegalForm = {
  company: string;
  directorFio: string;
  inn: string;
  kpp: string;
  ogrn: string;
  legalAddress: string;
  currentAccount: string;
  corrAccount: string;
  bik: string;
  bank: string;
};

export type TContactsForm = {
  fio: string;
  email: string;
  phone: string;
  company: string;
  companyBusiness: string;
};

export type TSelectedPlan = {
  name: string;
  id: string;
  period: string;
};

export type TPaymentData = {
  pay_amount: number;
  payment_invoice_id: string;
  payment_url: string;
  plan_name: string;
};
