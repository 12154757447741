import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import {
  RadioBlock,
  RadioBlockGroup,
} from '../../components/form-items/radio-block';
import { BaseModal } from '../../components/modal/base-modal';
import { EPaymentMethod } from '../../types/finance';

const Content = styled.div``;

const Warning = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: rgba(159, 158, 158, 1);
  margin: 7px 0 30px 0;
`;

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onNextStep: (method: EPaymentMethod) => void;
  t: TFunction<string[]>;
  buyTariffPlan: () => void;
  isLoadingPaymentPlan: boolean;
};

export const SelectPaymentMethodModal: FC<TProps> = ({
  open,
  setOpen,
  onNextStep,
  t,
  buyTariffPlan,
  isLoadingPaymentPlan,
}) => {
  const [currentMethod, setCurrentMethod] = useState<EPaymentMethod>(
    EPaymentMethod.Individual
  );

  const onConfirm = useCallback(() => {
    onNextStep(currentMethod);
    buyTariffPlan();
  }, [onNextStep, currentMethod]);

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={t('paymentMethodModal.title')}
      okText={t('paymentMethodModal.next')}
      onOk={onConfirm}
      t={t}
      onCancelClick={() => setOpen(false)}
      loading={isLoadingPaymentPlan}
    >
      <Content>
        <Warning>{t('paymentMethodModal.paymentWarning')}</Warning>
        <RadioBlockGroup>
          <RadioBlock
            name="payment-type"
            value={EPaymentMethod.Individual}
            checked={currentMethod === EPaymentMethod.Individual}
            onChange={setCurrentMethod}
            t={t}
          >
            {t('paymentMethodModal.individual')}
          </RadioBlock>
          <RadioBlock
            name="payment-type"
            value={EPaymentMethod.Legal}
            checked={currentMethod === EPaymentMethod.Legal}
            onChange={setCurrentMethod}
            disabled
            t={t}
          >
            {t('paymentMethodModal.legal')}
          </RadioBlock>
        </RadioBlockGroup>
      </Content>
    </BaseModal>
  );
};
