import styled from '@emotion/styled';
import { FC, useId } from 'react';

import { EFinancePeriod } from '../../types/finance';

const PlanMarker = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.COLORS.BLACK._700};
  transition: background-color 0.3s ease-out;

  @media (max-width: 948px) {
    display: none;
  }
`;

const Inner = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px 10px 12px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 50vb;
  cursor: pointer;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  user-select: none;
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;

  span {
    white-space: nowrap;
  }

  span:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 948px) {
    padding: 10px 20px;

    span {
      line-height: 19.2px;
      font-size: 14px;
    }
  }
`;

const Wrapper = styled.div`
  input:checked + ${Inner} {
    color: ${({ theme }) => theme.COLORS.ACCENT._200};

    ${PlanMarker} {
      background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
    }
  }
`;

type TProps = {
  name: string;
  label: string;
  defaultChecked?: boolean;
  value: EFinancePeriod;
  checked?: boolean;
  onChange: (value: EFinancePeriod) => void;
};

export const RadioTab: FC<TProps> = ({
  name,
  label,
  defaultChecked,
  value,
  checked,
  onChange,
}) => {
  const id = useId();

  return (
    <Wrapper>
      <input
        type="radio"
        hidden
        name={name}
        id={id}
        defaultChecked={defaultChecked}
        value={value}
        checked={checked}
        onChange={(e) => {
          onChange(e.target.value as EFinancePeriod);
        }}
      />
      <Inner htmlFor={id}>
        <PlanMarker />
        <span>{label}</span>
      </Inner>
    </Wrapper>
  );
};
