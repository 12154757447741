import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { boolToText, textToBool } from '../../utils/common';

const Wrapper = styled.div`
  width: calc(100% + 18px);
  margin-top: 20px;
  margin-left: -18px;
  padding-left: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.COLORS.BLACK._400};
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 948px) {
    padding-left: 36px;
  }
`;

const Inner = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 30px;
  position: relative;
`;

const TabItem = styled.li<{
  active?: 'true' | 'false';
  disabled?: boolean;
}>`
  padding-block: 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ active, theme }) =>
    textToBool(active) ? theme.COLORS.ACCENT._200 : 'inherit'};
  transition: color 0.3s ease-out;
`;

const Marker = styled.div`
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  transition:
    left 0.3s ease-out,
    width 0.3s ease-out;
`;

const tabList: {
  key: string;
  disabled?: boolean;
}[] = [
    {
      key: 'plans',
    },
  ];

type TProps = {
  t: TFunction<string[]>;
};

export const FinanceTabs: FC<TProps> = ({ t }) => {
  const [currentTab, setCurrentTab] = useState<string>(tabList[0].key);
  const markerRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLLIElement>(null);

  const onTabItemSelect: (
    e: Parameters<MouseEventHandler<HTMLLIElement>>[0],
    key: string
  ) => void = useCallback((e, key: string) => {
    setCurrentTab(key);
    if (markerRef.current) {
      const target: HTMLElement = e.target as HTMLElement;
      markerRef.current.style.left = target.offsetLeft + 'px';
      markerRef.current.style.width = target.clientWidth + 'px';
    }
  }, []);

  useEffect(() => {
    if (firstItemRef.current && markerRef.current) {
      markerRef.current.style.left = firstItemRef.current.offsetLeft + 'px';
      markerRef.current.style.width = firstItemRef.current.clientWidth + 'px';
    }
  }, []);

  return (
    <Wrapper>
      <Inner>
        {tabList.map((el, index) => (
          <TabItem
            ref={index === 0 ? firstItemRef : undefined}
            key={el.key}
            disabled={el.disabled}
            active={boolToText(currentTab === el.key)}
            onClick={
              !el.disabled ? (e) => onTabItemSelect(e, el.key) : undefined
            }
          >
            {t(`tabMenu.${el.key}`)}
          </TabItem>
        ))}
        <Marker ref={markerRef} />
      </Inner>
    </Wrapper>
  );
};
