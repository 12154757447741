import axios from 'axios';

import { BITRIX24_WEBHOOK_ADD_LEAD_SEKRET_KEY } from '../../constants';

type CreateLeadRequest = {
    fio: string;
    email: string;
    phone: string;
    company: string;
    companyBusiness: string;
};

export const createLead = (request: CreateLeadRequest) => {
    const webhookUrl = `https://neiroseti.bitrix24.ru/rest/499/${BITRIX24_WEBHOOK_ADD_LEAD_SEKRET_KEY}/crm.lead.add.json`;
    const data = {
        fields: {
            TITLE: `Заявка NeuroPLAi: ${request.fio} email: ${request.email || '---'} тел.: ${request.phone || ' ---'}`,
            NAME: request.fio,
            PHONE: [{ VALUE: request.phone, VALUE_TYPE: 'WORK' }],
            EMAIL: request.email,
            SOURCE_DESCRIPTION: `Компания: ${request.company}. Род деятельности: ${request.companyBusiness} `,
        },
        params: { REGISTER_SONET_EVENT: 'Y' },
    };

    return axios.post(webhookUrl, data);
};
