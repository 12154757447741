import styled from '@emotion/styled';
import { Button } from 'antd';

import { textToBool } from '../../../utils/common';

export const Wrapper = styled.div<{
  special: 'true' | 'false';
}>`
  background-color: ${({ theme, special }) =>
    textToBool(special)
      ? theme.COLORS.ACCENT._600_dark
      : theme.COLORS.BLACK._500};
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  border-radius: 16px;
  padding: 16px;
  max-width: 364px;
  min-width: 240px;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin-top: auto;
  }

  @media (max-width: 430px) {
    margin-bottom: 40px;
    max-width: 100%;
    padding: 20px;
  }
`;

export const PlanName = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.ACCENT._200};

  @media (min-width: 1200px) {
    font-size: 22px;
  }
`;

export const PlanType = styled.div`
  font-size: 13px;
  line-height: 110%;
  margin-bottom: 10px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK._900};
`;

export const Sum = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
`;

export const PerPeriod = styled.span`
  font-size: 11px;
  font-weight: 600;
`;

export const PlanFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 120%;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 28px;
  }
`;

export const FeatureLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.BLACK._900};
`;

export const FeatureValue = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const StyledButton = styled(Button) <{ isActivePlan: boolean }>`
  color: ${({ theme, isActivePlan }) =>
    isActivePlan
      ? theme.COLORS.BLACK._700
      : theme.COLORS.BLACK._200} !important;
  background-color: ${({ theme, isActivePlan }) =>
    isActivePlan
      ? theme.COLORS.BLACK._500
      : theme.COLORS.ACCENT._200} !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 14px;
  height: 42px;
  border-radius: 14px;
`;
