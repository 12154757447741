import styled from '@emotion/styled';
import { Button } from 'antd';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useMeTariffPlan } from '../../state/me/selectors';
import { EFinancePeriod } from '../../types/finance';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  column-gap: 30px;

  @media (max-width: 948px) {
    padding-left: 16px;
    flex-direction: column;
  }
`;

const PageTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 948px) {
    font-size: 30px;
    width: 100%;
  }
`;

const WrapperCurrentPlan = styled.div``;

const WrapperExtendPlan = styled.div`
  display: flex;

  @media (max-width: 948px) {
    margin-top: 20px;
    justify-content: space-between;
    column-gap: 20px;
  }
`;

const WrapperInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 948px) {
    flex-direction: column;
    background-color: ${({ theme }) => theme.COLORS.BLACK._400};
    padding: 16px;
    border-radius: 16px;
    margin-top: 20px;
  }
`;

const CurrentPlanSubText = styled.div`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};

  @media (max-width: 948px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const CurrentPlanText = styled.div`
  font-size: 16px;
  line-height: 100%;
`;

const ExcludeText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 110%;
  max-width: 230px;
  align-content: center;

  @media (max-width: 948px) {
    font-size: 14px;
  }
`;

const ExtendButton = styled(Button)`
  color: ${({ theme }) => theme.COLORS.BLACK._200} !important;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200} !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 14px;
  height: 42px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._200} !important;
  cursor: pointer;
  border-radius: 14px;
  padding: 14px 24px;
  color: ${({ theme }) => theme.COLORS.BLACK._400};
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 10px;

  @media (max-width: 948px) {
    padding: 10px 19.5px;
    font-size: 14px;
    font-weight: 400;
  }
`;

type TProps = {
  t: TFunction<string[]>;
  currentType: EFinancePeriod;
};

export const FinanceHeader: FC<TProps> = ({ t, currentType }) => {
  const myPlan = useMeTariffPlan();

  return (
    <Wrapper>
      <PageTitle>{t('header.pageTitle')}</PageTitle>
      <WrapperInfo>
        <WrapperCurrentPlan>
          <CurrentPlanSubText>
            {t('header.currentPlanSubText')}
          </CurrentPlanSubText>
          {myPlan ? (
            <CurrentPlanText>
              {t(`plan.${myPlan?.rate}`)}
              {currentType &&
                myPlan?.rate !== 'base' &&
                `(${t(`planPeriodVariants.${myPlan?.period}`)})`}
            </CurrentPlanText>
          ) : (
            <CurrentPlanText>-</CurrentPlanText>
          )}
        </WrapperCurrentPlan>

        {myPlan && !myPlan?.isUnlimited && (
          <WrapperExtendPlan>
            <ExcludeText>
              <Trans
                t={t}
                i18nKey="header.planEndIn"
                count={myPlan?.remainingTariffTime}
                components={{
                  mark: <mark />,
                }}
              />
            </ExcludeText>
            <ExtendButton type="primary">{t('header.extend')}</ExtendButton>
          </WrapperExtendPlan>
        )}
      </WrapperInfo>
    </Wrapper>
  );
};
