import { Button, Form, Input } from 'antd';
import App from 'antd/lib/app';
import { TFunction } from 'i18next';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { createLead } from '../../api/bitrix24';
import { DividerStyled } from '../../components/common/divider-styled';
import {
  BaseModal,
  ModalFooterWrapper,
} from '../../components/modal/base-modal';
import { useMeSelector } from '../../state/me/selectors';
import { Paths } from '../../types/common';
import { TContactsForm } from '../../types/finance';
import { FormRow, FormWrapper, LegalCheckBox } from './legal-form-modal';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  t: TFunction<string[]>;
  setOpenSendSuccessRequestModal: Dispatch<SetStateAction<boolean>>;
};

export const FinanceSendContacts: FC<TProps> = ({
  open,
  setOpen,
  t,
  setOpenSendSuccessRequestModal,
}) => {
  const [form] = Form.useForm<TContactsForm>();
  const [legalChecked, setLegalChecked] = useState(false);

  const { meData } = useMeSelector();

  const userName = useMemo(
    () =>
      meData && meData.firstName
        ? [meData.lastName, meData.firstName, meData.middleName]
          .filter(Boolean)
          .join(' ')
        : '',
    [meData]
  );

  const { message } = App.useApp();

  const onFinish = useCallback(async (values: TContactsForm) => {
    try {
      const response = await createLead(values);
      if (response.data) {
        message.success(t('contactsForm.successSendForm'));
        setOpen(false);
        setOpenSendSuccessRequestModal(true);
      } else throw new Error();
    } catch (err) {
      message.error(t('contactsForm.errorSendForm'));
    }
  }, []);

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={t('contactsForm.title')}
      width={600}
      t={t}
      footer={
        <ModalFooterWrapper>
          <LegalCheckBox
            checked={legalChecked}
            onChange={(e) => setLegalChecked(e.target.checked)}
          >
            <Trans
              t={t}
              i18nKey="contactsForm.acceptLegal"
              components={{
                Link: <Link to={Paths.PRIVACY_POLICY} target="_blank" />,
              }}
            />
          </LegalCheckBox>
          <Button type="primary" onClick={form.submit} disabled={!legalChecked}>
            {t('contactsForm.send')}
          </Button>
        </ModalFooterWrapper>
      }
    >
      <FormWrapper>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            label={t('contactsForm.fioLabel')}
            name="fio"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
            initialValue={userName}
          >
            <Input placeholder={t('contactsForm.fioPlaceholder')} />
          </Form.Item>
          <FormRow>
            <Form.Item
              label={t('contactsForm.emailLabel')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
              initialValue={meData?.email}
            >
              <Input placeholder={t('contactsForm.emailPlaceholder')} />
            </Form.Item>
            <Form.Item
              label={t('contactsForm.phoneLabel')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('formRules.required'),
                },
              ]}
            >
              <Input placeholder={t('contactsForm.phonePlaceholder')} />
            </Form.Item>
          </FormRow>
          <DividerStyled />
          <Form.Item
            label={t('contactsForm.companyName')}
            name="company"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('contactsForm.companyBusiness')}
            name="companyBusiness"
            rules={[
              {
                required: true,
                message: t('formRules.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </FormWrapper>
    </BaseModal>
  );
};
