import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  margin: 20px 0 0 0;
`;

export const Title = styled.span`
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  margin: 20px 0 0 0;
`;

export const ReadyButton = styled.button`
  width: 100%;
  cursor: pointer;
  max-width: 160px;
  padding: 16px;
  margin-top: 50px;
  border-radius: 10px;
  border: none;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._400};
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.COLORS.BLACK._400};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.ACCENT._500};
  }
`;
