import styled from '@emotion/styled';
import { Space } from 'antd';
import { TFunction } from 'i18next';
import { FC } from 'react';

import { EFinancePeriod } from '../../types/finance';
import { RadioTab } from '../common/radio-tab';
import ArrowLeftCurved from '../icons/arrow-left-curved';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 948px) {
    padding: 0px 16px;
    gap: 10px;
  }
`;

const SpaceStyled = styled(Space)`
  @media (max-width: 948px) {
    display: none;
  }
`;

const PromoText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  max-width: 360px;
  color: ${({ theme }) => theme.COLORS.ACCENT._200};
`;

type TProps = {
  t: TFunction<string[]>;
  period: EFinancePeriod;
  onPeriodChange: (period: EFinancePeriod) => void;
};

export const FinancePlanPicker: FC<TProps> = ({
  period,
  onPeriodChange,
  t,
}) => {
  return (
    <Wrapper>
      <RadioTab
        value={EFinancePeriod.MONTH}
        name="plan"
        label={t('planPeriodVariants.monthly')}
        checked={period === EFinancePeriod.MONTH}
        onChange={onPeriodChange}
      />
      <RadioTab
        value={EFinancePeriod.YEAR}
        name="plan"
        label={t('planPeriodVariants.annual')}
        checked={period === EFinancePeriod.YEAR}
        onChange={onPeriodChange}
      />
      <SpaceStyled align="center" size={20}>
        <ArrowLeftCurved />
        <PromoText>{t('tabMenu.promoText')}</PromoText>
      </SpaceStyled>
    </Wrapper>
  );
};
