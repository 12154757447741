import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ConfigProvider, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal/interface';
import { TFunction } from 'i18next';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
} from 'react';

import { DarkTheme } from '../../styles/theme';

type TProps = ModalProps & {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCancelClick?: () => void;
  t: TFunction<string[]>;
};

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  text-align: left;

  button {
    box-shadow: 0 2px 0 ${({ theme }) => theme.COLORS.BLACK._500};
    border-radius: 16px !important;
    font-size: 16px;
    line-height: 300%;
    height: auto;
    font-weight: 500;
    padding: 0;
    padding-inline: 20px;
  }
`;

const PaymentFooterRender: ModalProps['footer'] = (_, extra) => {
  return (
    <ModalFooterWrapper>
      <extra.CancelBtn />
      <extra.OkBtn />
    </ModalFooterWrapper>
  );
};

export const BaseModal: FC<PropsWithChildren<TProps>> = ({
  open,
  setOpen,
  children,
  onCancelClick,
  t,
  ...modalProps
}) => {
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const theme = useTheme();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.COLORS.ACCENT._200,
          colorTextBase: DarkTheme.COLORS.WHITE._100,
          colorBgBase: DarkTheme.COLORS.BLACK._200,
          colorPrimaryText: theme.COLORS.BLACK._200,
        },
        components: {
          Modal: {
            padding: 30,
            titleFontSize: 20,
            colorIcon: theme.COLORS.ACCENT._200,
            borderRadiusLG: 20,
          },
        },
      }}
    >
      <Modal
        {...modalProps}
        onCancel={onClose}
        open={open}
        footer={modalProps.footer ? modalProps.footer : PaymentFooterRender}
        wrapClassName="base-play-modal"
        cancelText={
          modalProps.cancelText
            ? modalProps.cancelText
            : t('tariffTextConstants.cancel')
        }
        cancelButtonProps={{
          type: 'primary',
          className: 'secondary-btn',
          onClick: onCancelClick,
        }}
      >
        {children}
      </Modal>
    </ConfigProvider>
  );
};
