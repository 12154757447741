import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../locale';

export const useBoolInText = (value: boolean) => {
    const { t } = useTranslation([LocaleKeys.FINANCE]);

    if (value) return t('tariffTextConstants.bool.yes');

    return t('tariffTextConstants.bool.no');
};
