import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC, useCallback } from 'react';

import masterCardIcon from '../../assets/master-card-icon.svg';
import mirIcon from '../../assets/mir-icon.svg';
import payKeeperIcon from '../../assets/pay-keeper-icon.svg';
import visaIcon from '../../assets/visa-icon.svg';
import { useAppSelector } from '../../state';
import { selectListTariffPlans } from '../../state/finance/selectors';
import { useLocaleSelector } from '../../state/locale/selector';
import { useMeTariffPlan } from '../../state/me/selectors';
import {
  EFinancePeriod,
  TFinancePlan,
  TSelectedPlan,
} from '../../types/finance';
import {
  getPlanPriceByPeriodAndLocale,
  mapTariffFeaturesToArray,
} from '../../utils/finance';
import { TariffPlan } from './tariff-plan';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 0 20px 10px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 948px) {
    padding: 0 20px 100px 20px;
    margin-top: 30px;
  }
`;

const List = styled.div<{
  itemsCount: number;
}>`
  width: 100%;
  max-width: 1920px;
  display: flex;
  gap: 20px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 6px;
    max-width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--theme-COLORS-BLACK-600);
    border-radius: 3px;
    width: 6px;
    height: 6px;
  }

  @media (max-width: 1440px) {
    overflow-x: auto;
  }

  @media (max-width: 430px) {
    display: block;
  }
`;

const PaymentInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 30px;

  @media (max-width: 430px) {
    padding: 0;
  }
`;

const CardItem = styled.div`
  width: 60px;
  height: 40px;

  img {
    object-fit: cover;
  }
`;

type TProps = {
  period: EFinancePeriod;
  selectNewTariffPlan: (plan: TSelectedPlan) => void;
  t: TFunction<string[]>;
};

export const FinanceTariffPlans: FC<TProps> = ({
  period,
  selectNewTariffPlan,
  t,
}) => {
  const { lang } = useLocaleSelector();

  const { list: tariffPlansList } = useAppSelector(selectListTariffPlans);

  const myPlan = useMeTariffPlan();

  const selectTariffPlan = useCallback((plan: TFinancePlan) => {
    const selectedPlan = {
      name: plan.rate,
      id: plan.id,
      period: plan.period,
    };

    localStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
    selectNewTariffPlan(selectedPlan);
  }, []);

  return (
    <Wrapper>
      <List itemsCount={tariffPlansList.length}>
        {tariffPlansList &&
          tariffPlansList.map((el) => (
            <TariffPlan
              key={el.id}
              planInfo={el}
              lang={lang}
              t={t}
              period={period}
              current={el.id === myPlan?.id}
              onSelect={() => selectTariffPlan(el)}
              special={false}
            />
          ))}
      </List>
      <PaymentInfoWrapper>
        <CardItem>
          <img src={visaIcon} alt="Visa" />
        </CardItem>
        <CardItem>
          <img src={masterCardIcon} alt="Mastercard" />
        </CardItem>
        <CardItem>
          <img src={mirIcon} alt="Мир" />
        </CardItem>
        <CardItem>
          <img src={payKeeperIcon} alt="PayKeeper" />
        </CardItem>
      </PaymentInfoWrapper>
    </Wrapper>
  );
};
