import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { DividerStyled } from '../../components/common/divider-styled';
import { BaseModal } from '../../components/modal/base-modal';
import { useAppSelector } from '../../state';
import {
  selectLegalReport,
  selectListTariffPlans,
} from '../../state/finance/selectors';
import { useLocaleSelector } from '../../state/locale/selector';
import { getPlanPriceByPeriodAndLocale } from '../../utils/finance';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  backToEdit: () => void;
  t: TFunction<string[]>;
};

const ReportMessage = styled.p`
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;

  &:first-of-type,
  &:nth-of-type(2) {
    margin-top: 0;
  }
`;

const ReportRow = styled.div`
  display: flex;
  gap: 20px;

  &:not(:first-of-type) {
    margin-top: 30px;
  }

  ${ReportItem} {
    flex-basis: 50%;
  }
`;

const Label = styled.h4`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
  color: ${({ theme }) => theme.COLORS.WHITE._200};
`;

const Value = styled.span`
  color: ${({ theme }) => theme.COLORS.WHITE._600};
  font-size: 14px;
  font-weight: 400;
`;

export const FinanceReportModal: FC<TProps> = ({
  open,
  setOpen,
  backToEdit,
  t,
}) => {
  const { lang } = useLocaleSelector();
  const { selectedPlan, selectedPeriod, legalForm } =
    useAppSelector(selectLegalReport);

  const { list: tariffPlans } = useAppSelector(selectListTariffPlans);

  const planSum = useMemo(() => {
    const plan = tariffPlans.find((el) => el.id === selectedPlan);

    if (!plan || !selectedPeriod || !selectedPlan) {
      return undefined;
    }

    return plan.price;
  }, [selectedPlan, selectedPeriod, lang]);

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={t('legalReport.modalTitle')}
      okText={t('legalReport.okText')}
      cancelText={t('legalReport.cancelText')}
      maskClosable={false}
      t={t}
      onCancelClick={() => {
        setOpen(false);
        backToEdit();
      }}
    >
      <ReportMessage>{t('legalReport.reportMessage')}</ReportMessage>
      <DividerStyled />
      <ReportRow>
        <ReportItem>
          <Label>{t('legalReport.plan')}</Label>
          <Value>
            {selectedPlan && t(`plan.${selectedPlan}`)} (
            {selectedPeriod && t(`planPeriodVariants.${selectedPeriod}`)})
          </Value>
        </ReportItem>
        <ReportItem>
          <Label>Тариф</Label>
          <Value>
            {typeof planSum === 'number'
              ? planSum.toLocaleString()
              : t('tariffTextConstants.needRequest')}
          </Value>
        </ReportItem>
      </ReportRow>
      <ReportItem>
        <Label>{t('legalForm.company')}</Label>
        <Value>{legalForm?.company}</Value>
      </ReportItem>
      <ReportItem>
        <Label>{t('legalForm.legalAddress')}</Label>
        <Value>{legalForm?.legalAddress}</Value>
      </ReportItem>
      <ReportItem>
        <Label>{t('legalForm.directorFio')}</Label>
        <Value>{legalForm?.directorFio}</Value>
      </ReportItem>
      <DividerStyled />
      <ReportRow>
        <ReportItem>
          <Label>{t('legalForm.inn')}</Label>
          <Value>{legalForm?.inn}</Value>
        </ReportItem>
        <ReportItem>
          <Label>{t('legalForm.kpp')}</Label>
          <Value>{legalForm?.kpp}</Value>
        </ReportItem>
      </ReportRow>
      <ReportRow>
        <ReportItem>
          <Label>{t('legalForm.bik')}</Label>
          <Value>{legalForm?.bik}</Value>
        </ReportItem>
        <ReportItem>
          <Label>{t('legalForm.bank')}</Label>
          <Value>{legalForm?.bank}</Value>
        </ReportItem>
      </ReportRow>
      <ReportItem>
        <Label>{t('legalForm.currentAccount')}</Label>
        <Value>{legalForm?.currentAccount}</Value>
      </ReportItem>
    </BaseModal>
  );
};
