import styled from '@emotion/styled';
import { Button, Space, message } from 'antd';
import { TFunction } from 'i18next';
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { API } from '../../api';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { resetVideoStateAction } from '../../state/video/byIdSlice';
import { useVideoSelector } from '../../state/video/selectors';
import { TLinkForm } from '../../types/responses/video';
import { EPlayerMode, ModeOptions } from '../../types/video';
import { getVideoItemPath } from '../../utils/paths';
import { BaseSettings } from '../form-items/base-settings';
import { BlockSelectable, TOption } from '../form-items/block-selectable';
import { SubtitlesArea } from '../form-items/subtitles';

const Wrapper = styled('div') <{ width: string }>`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  gap: 80px;
  padding-bottom: 60px;
  
  .setting-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media(max-width: 989px) {
    flex-direction: column;
    }
  }
  
   @media (max-width: 1490px) {
    width: 100%;
  }
}
`;

const Title = styled('span')`
  font-size: 2 0px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 120px;
  width: 100%;
  padding-top: 8px;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

const ButtonCancel = styled(Button)`
  max-width: 140px;
  width: 100%;
`;

const ButtonLink = styled(Button)`
  max-width: 280px;
  width: 100%;
`;
type TSettingItemProps = {
  title?: string;
};

const SettingItem: FC<PropsWithChildren<TSettingItemProps>> = ({
  title = '',
  children,
}) => {
  return (
    <Fragment>
      <div className="setting-container">
        <Title>{title}</Title>
        <div style={{ width: '100%' }}>{children}</div>
      </div>
    </Fragment>
  );
};

const getOptions: (t: TFunction<string[]>) => TOption[] = (t) => {
  return [
    {
      label: t(ModeOptions[EPlayerMode.AdvertisingMarketing]),
      value: EPlayerMode.AdvertisingMarketing,
    },
    {
      label: t(ModeOptions[EPlayerMode.Training]),
      value: EPlayerMode.Training,
    },
    {
      label: t(ModeOptions[EPlayerMode.HealthSafety]),
      value: EPlayerMode.HealthSafety,
    },
    {
      label: t(ModeOptions[EPlayerMode.KidsMode]),
      value: EPlayerMode.KidsMode,
    },
    {
      label: t(ModeOptions[EPlayerMode.CustomSettings]),
      value: EPlayerMode.CustomSettings,
    },
  ];
};

type TProps = {
  viewMode?: 'create' | 'edit';
  videoId: string;
  linkToEdit?: string;
  width: string;
};

export const Settings: FC<TProps> = ({ videoId, linkToEdit, width }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { linksData } = useVideoSelector();

  const [mode, setMode] = useState(EPlayerMode.AdvertisingMarketing);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [subtitles, setSubtitles] = useState(false);
  const [captions, setCaptions] = useState('');

  const [settings, setSettings] = useState<Record<string, any>>({});

  const option: TOption[] = useMemo(() => getOptions(t), [t]);

  const onBaseSettingsChange = useCallback(
    (formKey: string, formValue: any) => {
      setSettings((prev) => ({
        ...prev,
        [formKey]: formValue,
      }));
    },
    []
  );

  const handleCreate = useCallback(async () => {
    if (!title) {
      void message.error(t('settings.link.required'));
      return;
    }

    const data: TLinkForm = {
      ...(settings as TLinkForm),
      title: title,
    };

    const loading = message.loading(t('settings.link.loading'), 0);

    try {
      if (!videoId) {
        loading();
        return;
      }

      await API.videos.createLink(
        {
          videoId,
        },
        data,
        description
      );

      void message.success(t('settings.link.success'));
      dispatch(resetVideoStateAction());
      navigate(getVideoItemPath(videoId));
      loading();
    } catch (err: any) {
      if (
        err?.response?.data?.message.includes(
          'Video link creation limit exceeded'
        )
      ) {
        void message.error(t('settings.link.errorLimit'));
      } else {
        void message.error(t('settings.link.error'));
      }
      loading();
    }
  }, [settings, videoId, title, description]);

  const handleEdit = useCallback(async () => {
    if (!title) {
      void message.error(t('settings.link.required'));
      return;
    }

    if (linkToEdit) {
      const data: TLinkForm = {
        ...(settings as TLinkForm),
        title,
        playerMode: mode,
        captionsEnabled: subtitles,
        captions,
      };

      const loading = message.loading(t('settings.link.loading'), 0);

      try {
        if (!videoId) {
          loading();
          return;
        }

        await API.videos.editLink(
          {
            videoId,
          },
          data,
          linkToEdit,
          description
        );

        void message.success(t('settings.link.success'));
        dispatch(resetVideoStateAction());
        navigate(getVideoItemPath(videoId));
        loading();
      } catch (err: any) {
        void message.error(t('settings.link.error'));
        loading();
      }
    }
  }, [linkToEdit, settings, title, description, mode, subtitles, captions]);

  useEffect(() => {
    if (linkToEdit && linksData) {
      const linkToCopy = linksData.find((el) => el.id === linkToEdit);

      if (linkToCopy) {
        setSettings(() => ({
          viewingGuarantee: linkToCopy.videoSetting.viewingGuarantee,
          volumeLimit: linkToCopy.videoSetting.volumeLimit,
          volumeLimitValue: linkToCopy.videoSetting.volumeLimitValue,
          increaseAttention: linkToCopy.videoSetting.increaseAttention,
          increaseAttentionValue:
            linkToCopy.videoSetting.increaseAttentionValue,
          rewindOption: linkToCopy.videoSetting.rewindOption,
          distanceControl: linkToCopy.videoSetting.distanceControl,
          videoSpeedControl: linkToCopy.videoSetting.videoSpeedControl,
          videoRecommendations: linkToCopy.videoSetting.videoRecommendations,
          minimumAttentionLimit: linkToCopy.videoSetting.minimumAttentionLimit,
          minimumAttentionLimitValue:
            linkToCopy.videoSetting.minimumAttentionLimitValue,
          attentionLimit: linkToCopy.videoSetting.attentionLimit,
        }));

        setTitle(linkToCopy.videoSetting.title);
        setDescription(linkToCopy.description ?? '');
        setMode(linkToCopy.videoSetting.playerMode);
        setSubtitles(linkToCopy.videoSetting.captionsEnabled);
        setMode(linkToCopy.videoSetting.playerMode);
        setCaptions(linkToCopy.videoSetting.captions);
      }
    }
  }, [linkToEdit, linksData]);

  useEffect(() => {
    window.location.hash = 'settings';
    setTimeout(() => {
      window.location.hash = '';
    }, 100);
  }, [linksData]);

  return (
    <Wrapper width={width} id="settings">
      <SettingItem title={t('settings.general')}>
        <SubtitlesArea
          isInput
          text={title}
          setText={setTitle}
          placeholder={t('settings.link.title')}
        />
        <div
          style={{
            marginTop: 10,
          }}
        >
          <SubtitlesArea
            text={description}
            setText={setDescription}
            placeholder={t('settings.link.description')}
          />
        </div>
      </SettingItem>
      <SettingItem title={t('settings.player.title')}>
        <BlockSelectable
          options={option}
          value={mode}
          setValue={setMode as any}
        />
      </SettingItem>
      <SettingItem title={t('settings.title')}>
        <BaseSettings
          settings={settings}
          onBaseSettingsChange={onBaseSettingsChange}
        />
      </SettingItem>
      {/*<SettingItem title="Субтитры">*/}
      {/*  <Subtitles*/}
      {/*    value={subtitles}*/}
      {/*    setValue={setSubtitles}*/}
      {/*    text={captions}*/}
      {/*    setText={setCaptions}*/}
      {/*  />*/}
      {/*</SettingItem>*/}
      <SettingItem>
        <Space>
          <NavLink to={getVideoItemPath(videoId)}>
            <ButtonCancel size="large">{t('buttons.cancel')}</ButtonCancel>
          </NavLink>
          <ButtonLink
            size="large"
            onClick={linkToEdit ? handleEdit : handleCreate}
          >
            {!!linkToEdit ? t('buttons.link.change') : t('buttons.link.create')}
          </ButtonLink>
        </Space>
      </SettingItem>
    </Wrapper>
  );
};
